//  Table of Contents:
//
//   1. Global
//   2. Grid breakpoints
//   3. Base Typography
//   4. Typography Helpers
//   5. Abide
//   6. Accordion
//   7. Accordion Menu
//   8. ...
// 1. Global
// ---------

/*!
 * Primary color settings
 */

$blue-light: #40b4dc;
$blue-dark: #0c202f;
$red: #e80029;
$black: #000;
$white: #fff;
$grey-x-light: #f1f1f1;
$grey-light: #d2d2d2;
$grey-medium: #8c8c8c;
$grey-dark: #5a5a5a;
$grey-form: #c8c8c8;

// @font-face {
//   font-family: "Poppins";
//   src: url("/assets/fonts/poppins-regular-webfont.woff2") format("woff2"),
//     url("/assets/fonts/poppins-regular-webfont.woff") format("woff");
//   font-weight: 400;
//   font-style: normal;
// }

$primary-font-family: "Noto Sans",
Helvetica,
Arial,
sans-serif;
// font-family: Playfair Display

$primary-color: #2e4a86;
$secondary-color: $blue-light;
$third-color: rgba(206, 224, 2, 0.85);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
// $grid-breakpoints: ( xs: 0,
// sm: 576px,
// md: 768px,
// lg: 992px,
// xl: 1140px) !default;
// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints);