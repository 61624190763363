input[type="text"],
input[type="address"],
input[type="url"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="tel"],
textarea {
  display: inline-block;
  width: 100%;
  font-size: 1rem;
  border-radius: 0.75em;
  color: #3d3d3d;
  background-color: #fff;
  border: 0;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 0.2rem 1rem 0;
  height: 3.6em;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -webkit-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

textarea {
  padding-top: 0.8rem;
  height: 15rem;
  resize: none;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

input[type="text"]:focus,
input[type="address"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
textarea:focus {
  border-color: $primary-color;
  outline: 0;
}

input.input-gray,
textarea.input-gray {
  background: #f6f6f6;
}

input.input-gray:focus,
textarea.input-gray:focus {
  background: #fff;
}

input::-moz-placeholder {
  color: #999999;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: #999;
}

input::-webkit-input-placeholder {
  color: #999;
}

textarea::-moz-placeholder {
  color: #999;
  opacity: 1;
}

textarea:-ms-input-placeholder {
  color: #999;
}

textarea::-webkit-input-placeholder {
  color: #999;
}

.form-group {
  margin-bottom: 30px;
}

input.error {
  border-color: $primary-color;
}

.mc-label,
label.error {
  font-size: 0.83em;
  font-weight: 400;
  margin: 0.7em 0 0 0;
  color: #747474;
}
