/*-------------------------------------------------------------------------------
3. Homepage
-------------------------------------------------------------------------------*/

.wrapper {
  padding-top: 6.1rem;
  overflow-x: hidden;
  
}

.boxed {
  padding-left: 1.7rem;
  padding-right: 1.7rem;
}

.boxed .container,
.boxed .container-fluid {
  padding: 0;
  max-width: 100%;
}

.left-boxed {
  left: 1.7rem;
}

.right-boxed {
  right: 1.7rem;
}

.section {
  padding: 3.28rem 0;
}

.section-title {
  margin: 0;
}

.section-title .link-arrow-2 {
  margin-top: 1.05rem;
}

.section-subtitle {
  display: inline-block;
  font-weight: 500;
  font-size: 1.142rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $primary-color;
  margin-bottom: 1rem;
}

/* 3.1 Jumbotron */

.jumbotron {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  color: #fff;
  background-color: #fff;
}

/* 3.2 Slider */

.rev_slider .link-arrow {
  color: #fff;
}

.rev_slider .link-arrow:hover {
  color: $primary-color;
}

.rev_slider .link-arrow:hover .icon {
  -webkit-transform: none;
  -o-transform: none;
  transform: none;
}

.slider-prev,
.slider-next {
  position: absolute;
  top: 50%;
  z-index: 1;
  font-size: 4rem;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.slider-prev {
  left: 2.2rem;
}

.slider-next {
  right: 2.2rem;
}

.slider-prev:hover,
.slider-next:hover {
  color: $primary-color;
}

/* 3.3 Section About */

.section-about {
  padding: 8rem 0 0;
}

.section-about-title {
  margin-bottom: 2rem;
}

.dots-image {
  display: inline-block;
  position: relative;
}

.dots-image img {
  display: block;
  position: relative;
  z-index: 1;
}

.dots-image .dots {
  position: absolute;
  left: -20%;
  bottom: -15%;
  width: 101%;
  height: 57.2%;
  background: url(/assets/img/bg/dots.png) 0 0 repeat;
}

.experience-box {
  position: relative;
  white-space: nowrap;
  margin: 6rem 0;
}

@media (min-width: 992px) {
  .experience-box {
    margin-top: 6rem;
  }
}

@media (min-width: 1200px) {
  .experience-box {
    margin-top: 12.2rem;
  }
}

.experience-border {
  display: inline-block;
  vertical-align: middle;
  width: 12.15rem;
  height: 12.15rem;
  border: 0.357rem solid $primary-color;
}

.experience-content {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  left: -10.5rem;
  top: 0.2rem;
}

.experience-number {
  display: inline-block;
  vertical-align: middle;
  font-size: 7.142rem;
  line-height: 1;
  font-weight: 600;
  color: #000;
}

.experience-info {
  display: inline-block;
  vertical-align: middle;
  margin: -1rem 0 0 2.8rem;
  font-size: 1.57rem;
  line-height: 1.181;
  color: #000;
}

/* 3.4 Section Projects */

.section-projects {
  padding-bottom: 8rem;
}

.filter-content {
  position: relative;
  overflow: hidden;
}

.filter {
  list-style: none;
  font-size: 1.142rem;
  font-weight: 600;
  color: #000;
  padding-left: 0;
  margin: 0;
}

.filter li {
  margin: 0.6rem 2.6rem 0 0;
  display: inline-block;
}

.filter li:last-child {
  margin-right: 0;
}

.filter li a {
  color: inherit;
}

.filter li a:hover,
.filter .active a {
  color: $primary-color;
  text-decoration: none;
}

.section-projects .filter-content {
  margin: 2rem 0 0 0;
}

.section-projects .filter {
  margin: 0;
}

.section-projects .view-projects {
  margin: 0.85rem 0 0 0;
}

.view-projects {
  display: inline-block;
  font-style: italic;
  color: #999999;
}

.view-projects:hover {
  text-decoration: none;
  color: $primary-color;
}

/* 3.4.1 Projects Carousel */

.project-carousel {
  margin-top: 5.92rem;
}

.owl-nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.owl-nav .disabled {
  color: #bcbcbc;
}

.owl-prev,
.owl-next {
  display: inline-block;
  font-size: 2.8rem;
  line-height: 1;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.owl-prev:before {
  font-family: "Ionicons";
  content: "\f3d2";
}

.owl-next:before {
  font-family: "Ionicons";
  content: "\f3d3";
}

.owl-prev:hover,
.owl-next:hover {
  color: $primary-color;
}

.owl-dots {
  text-align: center;
  margin-top: 5.857rem;
}

.owl-dot {
  display: inline-block;
  vertical-align: top;
  margin: 0 0.6rem;
}

.owl-dot span {
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  background-color: #cccccc;
}

.owl-dot.active span {
  background-color: $primary-color;
}

.project-item {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.project-item img {
  width: 100%;
}

.item-shadow {
  position: relative;
}

.item-shadow:before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(top,
    rgba(0, 0, 0, 0) 46%,
    rgba(0, 0, 0, 0.6) 100%);
  background: linear-gradient(to bottom,
    rgba(0, 0, 0, 0) 46%,
    rgba(0, 0, 0, 0.6) 100%);
}

.project-item .link-arrow {
  position: absolute;
  z-index: 2;
  color: #fff;
  left: 3rem;
  bottom: 2.3rem;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.project-hover {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100.1%;
  height: 100%;
  padding: 4.85rem 3rem;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
}

.project-title {
  color: #fff;
  margin: 0;
  font-size: 2rem;
}

.project-hover .project-title {
  position: absolute;
  bottom: 5.5rem;
  max-width: 200px;
}

.project-description {
  opacity: 0;
  margin-top: 12%;
  line-height: 1.71;
  color: #333333;
  -webkit-transform: translateX(-1rem);
  -ms-transform: translateX(-1rem);
  -o-transform: translateX(-1rem);
  transform: translateY(-1rem);
}

.project-item:hover .project-hover {
  background-color: rgba(206, 224, 2, 0.85);
}

.project-item:hover .project-hover .project-title {
  position: relative;
  color: #000;
  bottom: 2rem;
  -webkit-transform: translateY(2rem);
  -ms-transform: translateY(2rem);
  -o-transform: translateY(2rem);
  transform: translateY(2rem);
  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
}

.project-item:hover .project-hover .project-description {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateY(0);
  -webkit-transition: all 0.4s 0.2s ease;
  -o-transition: all 0.4s 0.2s ease;
  transition: all 0.4s 0.2s ease;
}

.project-item:hover .link-arrow {
  color: #000;
}

/* 3.5  Section Clients */

.section-clients .section-title {
  margin-right: 7rem;
}

.client-carousel {
  margin-top: 4.8rem;
}

.client-carousel .client-carousel-item img {
  display: inline-block;
  width: auto;
}

.client-box {
  position: relative;
  color: #999;
  background-color: #fff;
  padding: 6rem 3.28rem 3.28rem;
}

.image-quote {
  position: absolute;
  top: 3.28rem;
  right: 3.28rem;
}

.client-img {
  position: relative;
  z-index: 1;
  margin: 0 0 -3rem 2.6rem;
  border-radius: 50%;
}

.client-title {
  letter-spacing: 0;
}

.client-name {
  font-size: 1.28rem;
  line-height: 1;
  color: #000;
}

.client-description {
  margin: 1.5rem 0 0;
  line-height: 1.71em;
}

.client-carousel .owl-nav {
  right: 0;
  top: -7.8rem;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.client-carousel .owl-prev {
  margin-right: 2rem;
}

/* 3.6 Section Partners */

.partner-carousel {
  margin-top: 10.28rem;
}

.partner-carousel .partner-carousel-item img {
  width: auto;
  margin: 0 auto;
  opacity: 0.6;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.partner-carousel .partner-carousel-item:hover img {
  opacity: 1;
}

/* 3.7 Section News */

.section-news {
  border-bottom: 1px solid #e1e1e1;
}

.products-carousel {
  margin-top: 2rem;
}

.news-carousel {
  margin-top: 4.7rem;
}

.news-item {
  position: relative;
}

.news-item img {
  width: 100%;
}

.news-hover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 2.7rem;
  color: #999;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.hover-border {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.hover-border div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.hover-border div::before {
  position: absolute;
  content: "";
  background: $primary-color;
  height: 2px;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.hover-border::after {
  position: absolute;
  content: "";
  background: $primary-color;
  width: 2px;
  height: 100%;
  right: 0;
  bottom: 0;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: 0.2s 0.2s;
  -o-transition: 0.2s 0.2s;
  transition: 0.2s 0.2s;
}

.hover-border div::after {
  position: absolute;
  content: "";
  background: $primary-color;
  width: 100%;
  height: 2px;
  right: 0;
  bottom: 0;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: 0.2s 0.4s;
  -o-transition: 0.2s 0.4s;
  transition: 0.2s 0.4s;
}

.hover-border::before {
  position: absolute;
  content: "";
  background: $primary-color;
  width: 2px;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: 0.2s 0.8s;
  -o-transition: 0.2s 0.8s;
  transition: 0.2s 0.8s;
}

.news-hover .content {
  opacity: 0;
  position: relative;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.news-hover .time {
  margin-bottom: 0.6rem;
}

.news-title {
  font-size: 1.95rem;
  line-height: 1.25;
  letter-spacing: 0;
  margin: 0;
  -webkit-transform: translateX(-2rem);
  -ms-transform: translateX(-2rem);
  -o-transform: translateX(-2rem);
  transform: translateX(-2rem);
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
}

.news-description {
  opacity: 0;
  margin-bottom: 0;
  line-height: 1.71;
  margin-top: 10%;
  -webkit-transform: translateY(-2rem);
  -ms-transform: translateY(-2rem);
  -o-transform: translateY(-2rem);
  transform: translateY(-2rem);
  -webkit-transition: 0.5s 0.3s ease;
  -o-transition: 0.5s 0.3s ease;
  transition: 0.5s 0.3s ease;
}

.news-hover .read-more {
  position: absolute;
  opacity: 0;
  bottom: 2.7rem;
}

.read-more {
  font-weight: 600;
}

.news-item:hover .news-hover {
  background-color: #fff;
}

.news-item:hover .hover-border::before,
.news-item:hover .hover-border::after,
.news-item:hover .hover-border div::before,
.news-item:hover .hover-border div::after {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.news-item:hover .news-hover .content {
  opacity: 1;
}

.news-item:hover .news-hover .news-title,
.news-item:hover .news-hover .news-description {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.news-item:hover .news-hover .read-more {
  opacity: 1;
}

.about-more {
  -webkit-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  transition: transform 0.3s;
}

.about-more:hover {
  -webkit-transform: translateX(0.5rem);
  -o-transform: translateX(0.5rem);
  transform: translateX(0.5rem);
}