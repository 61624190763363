/*-------------------------------------------------------------------------------
7. Footer
-------------------------------------------------------------------------------*/

.footer {
  color: #000;
  line-height: 1.71;
}

.footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer ul li a {
  color: #000;
}

.footer ul .active a,
.footer ul li a:hover {
  color: $primary-color;
  text-decoration: none;
}

.footer-flex {
  margin-top: -4rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer-flex .flex-item {
  margin-top: 4rem;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}