/*-------------------------------------------------------------------------------
6. Pages
-------------------------------------------------------------------------------*/

.display-1 {
  font-size: 4.28rem;
  line-height: 1;
  letter-spacing: -0.05em;
  margin: 0;
}

.display-2 {
  font-size: 5.14rem;
  line-height: 1;
  letter-spacing: -0.05em;
  margin: 0;
  text-transform: uppercase;
}

.text-display-1 {
  font-size: 1rem;
  // line-height: 2;
  letter-spacing: 0;
}

.text-display-1 p {
  margin-bottom: 1.5rem;
}

.text-display-1 p:last-child {
  margin-bottom: 0;
}

.title-hr {
  width: 7rem;
  max-width: 100%;
  height: 0.28rem;
  background: $primary-color;
}

.title-info {
  font-size: 1.28rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 600;
  color: #999999;
  margin-bottom: 1.4rem;
}

.page-header,
.page-header-2,
.page-header-3 {
  border-bottom: 0;
  margin: 8rem 0 7rem;
  padding: 0;
}

.page-header h1,
.page-header-2 h1,
.page-header-3 h1 {
  margin: 0;
}

.page-header-3 .title-hr {
  margin-bottom: 2.7rem;
}

.page-inner {
  margin-top: 4.28rem;
}

.section-info {
  color: #999999;
  line-height: 1.111;
  font-size: 1.28rem;
  text-transform: uppercase;
  font-weight: 600;
}

.section-info {
  margin-bottom: 4rem;
}

.section-info .title-hr {
  margin: 0 0 2rem;
}

.info-title {
  width: 1px;
}

.bg-dots {
  background: url(/assets/img/bg/dots2.png) 0 0 repeat;
}

/* 6.1 Works Grid */

.js-isotope {
  overflow: hidden;
}

.filter-content-2 {
  margin-bottom: 2.9rem;
}

.filter-content-2 .filter li,
.filter-content-3 .filter li,
.filter-content-4 .filter li {
  margin-right: 4rem;
}

.filter-content-3 {
  margin-bottom: 4.6rem;
}

.filter-content-4 {
  margin: 2rem 0 2rem;
}

.grid-items {
  margin: -30px -15px 0 -15px;
}

.grid-items:after {
  content: "";
  clear: both;
  display: block;
}

.grid-item {
  float: left;
  padding: 30px 15px 0 15px;
}

.project-hover-2 {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100.1%;
  height: 100%;
  padding: 2.71rem;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
}

.project-hover-2 .icon-plus {
  opacity: 0;
  position: absolute;
  color: #000;
  right: 2.71rem;
  font-size: 3rem;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.project-hover-2 .project-title {
  opacity: 0;
  position: absolute;
  bottom: 2.71rem;
  color: #000;
  -webkit-transform: translateX(-2rem);
  -ms-transform: translateX(-2rem);
  -o-transform: translateX(-2rem);
  transform: translateX(-2rem);
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.project-item:hover .project-hover-2 {
  background-color: $third-color;
}

.project-item:hover .project-hover-2 .project-title,
.project-item:hover .project-hover-2 .icon-plus {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

/* 6.2 Works Carousel */

.sly {
  position: relative;
  overflow: hidden;
}

.slidee {
  list-style: none;
  padding-left: 0;
}

.sly ul {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.sly ul li {
  float: left;
  width: 400px;
  height: 100%;
  margin: 0 30px 0 0;
  padding: 0;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  color: #fff;
  font-size: 4rem;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.prev.disabled,
.next.disabled {
  opacity: 0.5;
}

.prev:hover,
.next:hover {
  color: $primary-color;
}

.prev {
  left: 2.5rem;
}

.next {
  right: 2.5rem;
}

/* Scrollbar */

.scrollbar {
  margin: 6.8rem 0 0;
  height: 1px;
  background: #dae5e6;
  line-height: 0;
}

.scrollbar .handle {
  position: relative;
  top: -2px;
  width: 100px;
  height: 3px;
  background: #999999;
  cursor: pointer;
}

.scrollbar .handle .mousearea {
  position: absolute;
  top: -9px;
  left: 0;
  width: 100%;
  height: 20px;
}

/* 6.3 Project Detail */

.project-detail {
  position: relative;
  overflow: hidden;
}

.project-detail-item {
  min-height: 420px;
  background-size: cover;
  background-position: 50% 50%;
  padding: 6.78rem 11.2rem;
}

.project-detail-info {
  // background-color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 3.3rem;
  //width: 410px;
}

.project-detail-control {
  display: inline-block;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.project-detail-control:hover {
  color: $primary-color;
}

.project-detail-control .show-info {
  display: none;
}

.project-detail-control.active .show-info {
  display: block;
}

.project-detail-control.active .hide-info {
  display: none;
}

.project-detail-title {
  font-size: 2.57rem;
  letter-spacing: -0.025em;
  line-height: 1.16666;
  margin: 3rem 0 2.4rem 0;
}

.project-detail-list {
  list-style: none;
  padding-left: 0;
  margin-top: 3.6rem;
}

.project-detail-list li {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 0.6rem;
  margin-top: 0.65rem;
  overflow: hidden;
}

.project-detail-list li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.project-detail-list li .left {
  float: left;
  font-weight: 600;
}

.project-detail-list li .right {
  float: right;
}

.project-detail-meta {
  margin-top: 4.5rem;
  overflow: hidden;
}

.project-detail-meta .left {
  font-weight: 600;
}

/* 6.4 News Masonry */

.card img {
  display: block;
  width: 100%;
}

.card-block {
  background: #f2f2f2;
  padding: 2.5rem;
  line-height: 1.714;
  background: #f2f2f2;
  -webkit-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
}

.card-block:hover {
  background-color: $third-color;
}

.card-posted {
  margin-bottom: 0.8rem;
}

.card-posted a {
  color: #000;
}

.card-posted a:hover {
  color: #000;
}

.card-title {
  letter-spacing: 0;
  margin: 0;
}

.card-text {
  margin-top: 2rem;
}

.card-read-more {
  display: inline-block;
  margin-top: 2rem;
  font-weight: 600;
  color: #000;

  &.about {
    margin-top: 0rem;
  }
}

.card-read-more:hover {
  color: #000;
}

// .card:hover .card-block {
//   background: $third-color;
// }

/* 6.5 News Listing */

.card-row {
  position: relative;
  // background: #f2f2f2;
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  margin-bottom: 5rem;
  width: 100%;
}

.card-row:last-child {
  margin-bottom: 0;
}

.card-row-img {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  height: 100%;
  background-size: cover;
  background-position: center 0;
}

.card-row-img img {
  width: 100%;
}

// .card-row:hover {
//   background-color: $third-color;
// }

.card-row .card-block {
  padding: 3.6rem;
  // background: transparent;
}

.card-row .card-read-more {
  margin-top: 5vw;
}

/* 6.6 Post Detail */

.secondary {
  margin-top: 6rem;
}

.post-gallery {
  position: relative;
  overflow-y: hidden;
}

.header-space {
  margin-top: 7rem;
}

.post-thumbnail {
  background: url(/assets/img/news/1-1920x1080.jpg) 50% no-repeat;
  background-size: cover;
  padding: 16.4rem 15px 19rem;
}

.post-thumbnail h1,
.post-thumbnail .title-info {
  color: #fff;
}

.product-header {
  padding: 16.4rem 15px 19rem;
  position: absolute;
  top: 0;

  h1,
  .title-info {
    color: #fff;
  }
}

.page-content {
  margin-top: 8.2rem;
}

.page-content-2 {
  margin-top: 6.6rem;
}

.posted-on {
  font-size: 1.142rem;
  margin-bottom: 7rem;
}

.posted-on a {
  color: #000;
}

.posted-on a:hover {
  color: $primary-color;
  text-decoration: none;
}

.entry-title {
  margin: 0 0 2.5rem;
  letter-spacing: -0.05em;
  line-height: 1.125;
}

.entry-description {
  letter-spacing: 0;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 4.8rem;
  margin-top: 0;
}

.entry-content {
  font-size: 1.2857rem;
  line-height: 1.666;
  font-weight: 300;
  letter-spacing: 0;
}

.row-images {
  padding: 4.54rem 0;
}

.row-images .col-image {
  margin-top: 30px;
}

.row-images .col-image:first-child {
  margin-top: 0;
}

.row-images .col-image img {
  width: 100%;
}

.entry-footer {
  padding-top: 4rem;
}

.tags-links span {
  color: #000;
}

.tags-links,
.tags-links a {
  color: #999999;
}

.tags-links a:hover {
  color: #cc0000;
  text-decoration: none;
}

.post-share {
  margin-top: 1rem;
}

.post-share span {
  color: #000;
}

.post-share a {
  color: #cccccc;
  font-size: 1.4rem;
  vertical-align: middle;
  margin-left: 0.8rem;
}

.post-share a:hover {
  color: $primary-color;
}

/* 6.6.1 Related Posts */

.related-posts {
  margin-top: 6rem;
  padding-top: 6rem;
  border-top: 1px solid #e1e1e1;
}

.related-post-title {
  margin: 0;
}

/* 6.6.2 Comments */

.section-comments {
  margin-top: 6.8rem;
  padding: 7.3rem 15px;
  background-color: #f5f5f5;
}

.comments-title {
  margin: 0;
  margin-bottom: 2.85rem;
}

.comment-list {
  padding-left: 0;
  list-style: none;
}

.comment .avatar {
  float: left;
  margin-right: 2.14rem;
}

.comment-content {
  overflow: hidden;
  padding: 1.2rem 0 3rem 1.5rem;
  border-top: 1px solid #d8d8d8;
}

.comment-content p {
  margin-bottom: 0;
}

.comment-metadata {
  margin-bottom: 0.6rem;
}

.comment-metadata .fn a {
  color: #333333;
}

.comment-metadata .fn a:hover {
  color: $primary-color;
  text-decoration: none;
}

.comment-time {
  color: #999999;
  font-size: 11px;
  text-transform: uppercase;
}

.comment-time:hover {
  color: #000;
  text-decoration: none;
}

.reply {
  margin-top: 0.7rem;
}

.reply a {
  color: #999999;
  font-style: italic;
}

.reply a:hover {
  color: #0099cc;
  text-decoration: none;
}

.comment-reply-title small {
  float: right;
  margin-top: 0.5rem;
}

/* 6.7 Widgets */

.widget {
  margin-bottom: 4.5rem;
  padding-bottom: 5rem;
  border-bottom: 1px solid #e1e1e1;
}

.widget:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.widget-title {
  font-size: 1.2758rem;
  letter-spacing: 0;
  margin: 0 0 3rem;
}

.widget_archive li,
.widget_categories li,
.widget_pages li,
.widget_meta li,
.widget_recent_entries li,
.widget_nav_menu li {
  margin-top: 0.5em;
}

.widget_archive li a,
.widget_categories li a,
.widget_pages li a,
.widget_meta li a,
.widget_recent_entries li a,
.widget_nav_menu li a {
  color: #666666;
}

.widget_archive li a:hover,
.widget_categories li a:hover,
.widget_pages li a:hover,
.widget_meta li a:hover,
.widget_recent_entries li a:hover,
.widget_nav_menu li a:hover {
  color: $primary-color;
  text-decoration: none;
}

.widget_categories li {
  text-align: right;
}

.widget_categories li a {
  float: left;
}

/* 6.7.1 Widget Search */

.search-form {
  position: relative;
}

.search-form .search-field {
  background: #f6f6f6;
  border-color: transparent;
}

.search-form .search-field:focus {
  background: none;
}

.search-submit {
  position: absolute;
  background: none;
  border: 0;
  right: 0.8rem;
  top: 1.1rem;
}

.search-submit:focus {
  outline: none;
}

/* 6.7.2 Widget Twitter */

.widget ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.widget_twitter li {
  margin-bottom: 2.2rem;
}

.widget_twitter li:last-child {
  margin-bottom: 0;
}

.widget_twitter li a {
  color: #0099cc;
}

.tweet-time {
  display: block;
  margin-top: 0.4rem;
  font-size: 0.857rem;
  display: block;
  color: #999999;
  text-transform: uppercase;
}

.widget-all-items {
  display: inline-block;
  margin-top: 1.4rem;
  color: #999999;
  font-size: 0.928rem;
  text-transform: uppercase;
  font-weight: 600;
}

.widget-all-items:hover {
  text-decoration: none;
}

/* 6.7.3 Widget Recent Posts */

.widget_recent_posts li {
  overflow: hidden;
  margin-bottom: 2.214rem;
}

.widget_recent_posts li:last-child {
  margin-bottom: 0;
}

.recent-post-thumbnail {
  float: left;
  margin-right: 1.5rem;
}

.recent-post-content {
  overflow-y: hidden;
  line-height: 1.29;
}

.post-title {
  display: inline-block;
  font-weight: 600;
  letter-spacing: 0;
  color: #000;
}

.post-title:hover {
  color: $primary-color;
  text-decoration: none;
}

.post-time {
  display: block;
  margin-top: 0.6rem;
  font-size: 0.857rem;
  color: #999999;
}

/* 6.8 About Us */

.about-info {
  padding-top: 0;
}

.content-entry-image {
  padding-top: 38%;
  min-height: 28rem;
  background: url(/assets/img/1-1920x800.jpg) 50%;
  background-size: cover;
}

/* 6.8.1 Services */

.row-services {
  margin: 0rem -2.5rem 0 -2.5rem;
}

.col-service {
  color: #333;
  margin-top: 2rem;
  padding: 0 2rem;
}

.col-service h4 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.414rem;
}

.col-service p {
  margin-bottom: 0;
}

/* 6.8.2 Partners */

.row-partners {
  margin: -5rem -1rem 0;
}

.col-partner {
  padding: 0 1rem;
  margin-top: 5rem;
  float: left;
}

.col-partner img {
  max-width: 100%;
}

/* 6.8.3 Team */

.row-team {
  margin-top: -30px;
}

.col-team {
  margin-top: 30px;
}

.team-profile {
  position: relative;
}

.team-profile img {
  width: 100%;
  display: block;
}

.team-hover {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(206, 224, 2, 0.85);
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.team-content {
  position: absolute;
  left: 1.857rem;
  bottom: 0.8rem;
}

.team-name {
  margin-bottom: 0.3rem;
}

.team-name h6 {
  margin: 0;
}

.team-social a {
  float: left;
  color: #000;
  font-size: 1.25rem;
  margin-right: 1.5rem;
}

.team-social a:hover {
  color: #fff;
  text-decoration: none;
}

.team-profile:hover .team-hover {
  opacity: 1;
}

.add-new-team {
  position: relative;
  border: 1px solid #bfbfbf;
  padding-top: 100%;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.add-new-text {
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  text-align: center;
  font-size: 1.285rem;
  line-height: 1.1111;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.add-new-text .inner {
  display: inline-block;
  text-align: left;
}

.add-new-team input[type="file"] {
  position: absolute;
  cursor: pointer;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.add-new-team:hover {
  background: $primary-color;
  border-color: transparent;
}

/* 6.9 Contacts */

.map {
  width: 100%;
  height: 26rem;
  border: 0;
  text-align: center;
}

.map-info {
  color: #000;
  text-align: left;
  padding: 1rem 0;
}

.map-address-row {
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.4;
}

.map-address-row .fa {
  margin-right: 0.5rem;
}

.gmap-open {
  margin: 2rem 0 0;
}

.row-contact {
  margin: -6rem -3.5rem 0;
}

.col-contact {
  margin-top: 6rem;
  padding: 0 3.5rem;
}

.contact-title {
  letter-spacing: 0;
  margin: 0;
}

.contact-top {
  margin-bottom: 1.3rem;
}

.contact-title span {
  color: #ccc;
  font-weight: 400;
}

.contact-address {
  font-size: 1.142rem;
  line-height: 1.875;
  letter-spacing: -0.025rem;
  margin-bottom: 2rem;
}

.contact-row {
  margin-bottom: 0.6rem;
}

.phone-lg {
  font-size: 2.142rem;
  line-height: 1.3;
  margin-bottom: 3.6rem;
}

.contact-social {
  margin-top: 1rem;
}

.contact-social a {
  color: #999999;
  font-size: 1.3rem;
}

.section-message {
  padding-top: 8rem;
  margin-left: 16px;
}