html {
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: $primary-font-family;
  // font-size: 0.85rem;
  font-size: 13px;
  font-weight: 400;
  // letter-spacing: 0.025em;
  line-height: 1.3428;
  color: #666666;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: $primary-color;
}

::selection {
  background-color: $primary-color;
  color: #fff;
}

-webkit-::selection {
  background-color: $primary-color;
  color: #fff;
}

::-moz-selection {
  background-color: $primary-color;
  color: #fff;
}

/* 1.2 Typography */

@import "typography";

/* 1.3 Fields */

@import "fields";

/* 1.4 Buttons */

@import "buttons";

/* 1.6 Preloader */

@import "preloader";

/* 1.7 Helpers */

.text-dark {
  color: #000;
}

.text-muted {
  color: #999;
  margin-bottom: 1rem !important;
}

.text-white {
  color: #fff;
}

.text-primary {
  color: $primary-color;
}

.masked {
  position: relative;
}

.masked:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.1;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.rel-1 {
  position: relative;
  z-index: 1;
}

.overflow-hidden {
  overflow: hidden;
}