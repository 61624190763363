.btn {
  color: #fff;
  display: inline-block;
  border-radius: 3;
  padding: 10px 24px;
  background-color: #2e4a86 ;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out !important;
  transition: all 0.3s ease-out !important;
}

.btn:hover,
.btn:focus {
  background-color: $primary-color;
  color: rgb(255, 255, 255);
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none !important;
}

// .btn-upper {
//   font-weight: 600;
// }

.btn-yellow {
  background: $primary-color;
  color: #fff;
}

// .btn-yellow:hover,
// .btn-yellow:focus {
//    background: #597ecc;
// }

/* 1.5 Icons */

.icon {
  display: inline-block;
}

.icon-medium {
  font-size: 4.2rem;
}

.link-arrow {
  display: inline-block;
  color: #000;
  line-height: 1 !important;
  font-weight: 600;
}

.link-arrow-2 {
  display: inline-block;
  font-size: 1.14rem;
  color: #999999;
  font-weight: 400;
}

.link-arrow .icon,
.link-arrow-2 .icon {
  position: relative;
  margin-left: 0.7rem;
  font-size: 2.1em;
  vertical-align: middle;
  -webkit-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  transition: transform 0.3s;
}

.link-arrow .icon {
  top: 0.1rem;
}

.link-arrow .ion-ios-arrow-right {
  font-size: 1.5em;
}

.link-arrow-2 .ion-ios-arrow-right {
  font-size: 1.5em;
}

.link-arrow:hover,
.link-arrow-2:hover {
  color: $primary-color;
  text-decoration: none;
}

.link-arrow:hover .icon,
.link-arrow-2:hover .icon {
  -webkit-transform: translateX(0.5rem);
  -o-transform: translateX(0.5rem);
  transform: translateX(0.5rem);
}
