h1,
.h1 {
  font-weight: 700;
  font-size: 3.428rem;
  line-height: 1.2;
  font-weight: 600;
  color: #000;
  letter-spacing: -0.025em;
  margin: 0.5em 0;
}

h2,
.h2 {
  font-weight: 600;
  font-size: 2.571rem;
  line-height: 1.166;
  color: #000;
  letter-spacing: 0;
  margin: 2.6rem 0;
}

h3,
.h3 {
  font-weight: 600;
  font-size: 2.142rem;
  line-height: 1.2;
  letter-spacing: -0.05em;
  color: #000;
  margin: 2.6rem 0;
}

h4,
.h4 {
  font-weight: 600;
  font-size: 1.714rem;
  line-height: 1.25;
  letter-spacing: 0.025em;
  color: #000;
  margin: 2.6rem 0;
}

h5,
.h5 {
  font-weight: 600;
  font-size: 1.571rem;
  line-height: 1.1;
  letter-spacing: 0;
  color: #000;
  margin: 2.6rem 0;
}

h6,
.h6 {
  font-weight: 600;
  font-size: 1.285rem;
  line-height: 1.3333;
  letter-spacing: 0;
  color: #000;
  margin: 2.6rem 0;
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  color: inherit;
}

h1 a:hover,
.h1 a:hover,
h2 a:hover,
.h2 a:hover,
h3 a:hover,
.h3 a:hover,
h4 a:hover,
.h4 a:hover,
h5 a:hover,
.h5 a:hover,
h6 a:hover,
.h6 a:hover {
  text-decoration: none;
}

strong {
  font-weight: 600;
}

p,
blockquote {
  margin-bottom: 2.6rem;
}

blockquote {
  font-family: "Playfair Display", serif;
  font-size: 1.714rem;
  letter-spacing: 0.025em;
  line-height: 1.5;
  padding: 0;
  width: 80%;
  padding-left: 6.8rem;
  border-left: 0.3rem solid $primary-color;
  margin-top: 5rem;
  color: #000;
}

cite {
  display: block;
  color: #000;
  font-weight: 600;
  letter-spacing: 0;
  font-style: normal;
}

.blockquote-cite {
  font-size: 1rem;
  color: #999999;
  letter-spacing: 0.05em;
  padding-left: 7.1rem;
  margin-bottom: 5rem;
}

.blockquote-cite cite {
  font-size: 1.14rem;
}

a {
  color: $primary-color;
  -webkit-transition: color 0.3s ease-out;
  -o-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
}

a:hover,
a:focus {
  color: $primary-color;
  outline: none;
}

a:focus {
  text-decoration: none;
}
