.about-video {
  height: 69rem;
  position: relative;

  @media (max-width: 1600px) {
    height: 55rem;
  }

  @media (max-width: 1024px) {
    height: 35rem;
  }

  @media (max-width: 768px) {
    height: 30rem;
  }

  @media (max-width: 500px) {
    height: 15rem;
  }
}

#video {
  position: relative;
  background: transparent;
}

.ytplayer-container {
  position: absolute;
  top: 0;
  z-index: -1;
}

.bg-section {
  position: relative;

  .bg-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .bg-background-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .bg-background-inner {
        bottom: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }

    .bg-background-overlay {
      background-color: #faf9f8;
      bottom: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}

.bg-about {
  background-image: url(/assets/img/about/bg_sait.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

// .rs-background-video-layer[data-ytid] iframe {
//   visibility: visible !important;
// }

// .tp-videolayer[data-ytid] iframe {
//   visibility: visible !important;
//   opacity: 1 !important;
// }